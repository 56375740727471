@import "~bootstrap/scss/functions";
@import "_variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "../font-awesome/scss/font-awesome.scss";

* {
    font-family: 'Roboto', sans-serif;
}

label.klauzula {
    font-size: 0.7rem;
}
label.zaznacz-wszystkie {
    font-size: 0.85rem;
}

.cur-pointer {
    cursor: pointer;
}
